.App {
    text-align: center;
}

/* Hide scrollbar but allow scrolling */
body {
    overflow-y: scroll!important; /* Always show a scrollbar to prevent layout shifting */
  }
  
  /* Hide scrollbar for modern browsers */
  body::-webkit-scrollbar {
    width: 0px!important;  /* Remove scrollbar space */
    background: transparent!important;  /* Optional: just make scrollbar invisible */
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  body {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  /* Hide scrollbar but allow scrolling */
body {
    scrollbar-width: none; /* Firefox */
  }
  
  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  body::-webkit-scrollbar {
    display: none;
  }

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.with-shadow img {
    width: 100px; /* Added width */
    height: 100px; /* Added height */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); /* Added box-shadow with darker color */
  }
  
