:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #8b1f24;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #8b1f24;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}



@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5gU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5pU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5iU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5jU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBs5tU1ECVZl_.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BM5gU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BM5pU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BM5iU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BM5jU1ECVZl_86Y.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BM5tU1ECVZl_.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  
  #admin {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dcbbbd;
    height: 100vh;
  } 
  
  .screen-1 {
    background: #8b1f24;
    padding: 2em;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    box-shadow: 0 0 1em #b9787b;
    gap: 2em;
  }
  .screen-1 .logo {
    margin-top: -3em;
  }
  .screen-1 .email {
    background: white;
    box-shadow: 0 0 1em #b9787b;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
    margin-top: -3em;
  }
  .screen-1 .email input {
    outline: none;
    border: none;
  }
  .screen-1 .email input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email input::placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .email ion-icon {
    color: #b9787b;
    margin-bottom: -0.2em;
  }
  .screen-1 .password {
    background: white;
    box-shadow: 0 0 1em #b9787b;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    border-radius: 20px;
    color: #4d4d4d;
  }
  .screen-1 .password input {
    outline: none;
    border: none;
  }
  .screen-1 .password input::-moz-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password input:-ms-input-placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password input::placeholder {
    color: black;
    font-size: 0.9em;
  }
  .screen-1 .password ion-icon {
    color: #4d4d4d;
    margin-bottom: -0.2em;
  }
  .screen-1 .password .show-hide {
    margin-right: -5em;
  }
  .screen-1 .login {
    padding: 1em;
    background: #b9787b;
    gap: 2em;
    color: white;
    border: none;
    border-radius: 30px;
    font-weight: 600;
  }
  .screen-1 .footer {
    display: flex;
    font-size: 0.7em;
    color: white;
    gap: 14em;
    padding-bottom: 10em;
  }
  .screen-1 .footer span {
    cursor: pointer;
  }
  
  button {
    cursor: pointer;
  }